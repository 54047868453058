import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Swiper, {Navigation} from 'swiper';
Swiper.use([Navigation]);
import 'swiper/css/bundle';
import 'fslightbox';

gsap.registerPlugin(ScrollTrigger);
gsap.config({
    nullTargetWarn: false
});

window.addEventListener("DOMContentLoaded", () => {
    init();
});

function init() {
    sliders();
    sectionSpacing();
}

function sliders() {
    new Swiper('.c-realisations__swiper', {
        navigation: {
            nextEl: ".c-realisations__btn--next",
            prevEl: ".c-realisations__btn--prev",
        },
        spaceBetween: 40,
        speed: 600,
        breakpoints: {
            400: {
                slidesPerView: 1.2
            },
            576: {
                slidesPerView: 1.2
            },
            768: {
                slidesPerView: 2.2
            },
            992: {
                slidesPerView: 3
            }
        }
    });
    new Swiper('.c-slider__swiper', {
        slidesPerView: 1.2,
        centeredSlides: true,
        spaceBetween: 100,
        navigation: {
            nextEl: ".c-slider__btn--next",
            prevEl: ".c-slider__btn--prev",
        },
        breakpoints: {
            400: {
                spaceBetween: 30
            },
            576: {
                spaceBetween: 40
            },
            768: {
                spaceBetween: 50
            },
            992: {
                spaceBetween: 100
            }
        },
        speed: 600
    });

}

function sectionSpacing() {
    const main = document.querySelector('main');
    const sections = main.querySelectorAll('section');
    const bgPrimaryClass = 'o-section--primary';
    const bgClass = 'o-section--bg';
    const bgSecondaryClass = 'o-section--secondary';
    const bgTertiairyClass = 'o-section--tertiairy';
    
    if (!sections.length > 1) return;

    removeSpacing(sections);

    function removeSpacing(section) {

        section.forEach((e) => {
            let sectionPrev = e.previousElementSibling;
            let sectionNext = e.nextElementSibling;

            if (sectionPrev != null) {
                if (containsClass(e, bgPrimaryClass) && containsClass(sectionPrev, bgPrimaryClass) || containsClass(e, bgSecondaryClass) && containsClass(sectionPrev, bgSecondaryClass) || containsClass(e, bgTertiairyClass) && containsClass(sectionPrev, bgTertiairyClass)) {
                    e.classList.add('o-section--pt-0');
                }
            }

            if (sectionNext != null) {
                if (containsClass(e, bgSecondaryClass) && containsClass(sectionNext, bgSecondaryClass) == false || containsClass(e, bgTertiairyClass) && containsClass(sectionNext, bgTertiairyClass) == false) {
                    e.classList.add('o-section--bb');
                }
            }

            if (sectionNext != null) {
                if (containsClass(sectionNext, bgClass) == false) {
                     e.classList.add('o-section--bt-overlap');
                }
            }
        });

        function containsClass(section, className) {
            return section.classList.contains(className);
        }
    }
}
